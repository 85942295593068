.full-window {
    height: 80vh;
    display: flex;
    align-items: center;
    text-align: left;
}

.hero-title {
    font-size: 3.5rem;
    margin-bottom: 0.9rem;
    display: flex;
}

.Typewriter{
    margin-left: 16px;
    color: color(primary);
}

.container-hero {
    margin: 0rem 5rem 
}

.button-container-hero {
    margin-bottom: 1rem;
}

.listing-logo {
    margin-left: 0 !important;
    margin-right: 1rem !important;
}

@include media( '<=small' ) {
    .button-container-hero {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@include media( '<=large' ) {

    .full-window { 
        height: 60vh;
    }

    .hero-title {
        font-size: 1.65rem;
        margin-bottom: 0rem;
    }

    .container-hero {
        margin: 0rem 1rem 
    }

    .Typewriter{
        margin-left: 8px; 
    }
}