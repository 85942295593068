.button {
	@include font-family(heading);
	box-shadow: 0 8px 20px rgba(get-color(dark, 1), .32);
}

.button-primary-border {
	color: color(button-primary);
    background-color: transparent;
	border: 2px solid color-bg(button-primary);
	border-color: color-border(button-primary);
	margin-left: 18px;

    &:hover {
        background-color: color-bg(button-primary-hover);
		border-color: color-border(button-primary-hover);
    }

	&.is-loading {

		&::after {
			border-color: color(button-primary);
		}
	}

	@include media( '<=small' ) {
		margin-left: 0px;
	}
}
