.pool-list-page {
    margin-top: 2rem;
}

.loading-container  {
    height: 30vh;
    padding: 5rem;
}

.pool-card {
    border: 1.5px solid color(primary);
    border-radius: 10px;
    margin-top: 22px;
    margin-bottom: 22px;
    background-color: color(heading-inverse);
    .register-now-text {
        margin-bottom: 6px !important;
    }
    .ido-date {
        .text-sm {
            font-size: 16px !important;
        }
        
    }
    .whitelist-button {
        background-color: color(primary);
        padding: 1px 13px;
        width: fit-content;
        height: 27px;
        font-size: 13px;
        
    }

    .disabled {
        color: rgb(165, 158, 158);
    }
    &:hover {
        background-color: color(mid-contrast-inverse);
        margin-top: 21.5px;
        margin-bottom: 21.5px;
        cursor: pointer;
        transition: 0.2s;
    }
    .pool-card-status {
        width: fit-content;
        // padding: 0 1rem;
        margin-left: auto;
        margin-top: -16px;
        margin-right: 1rem;
        h6 {
            border-radius: 20px;
            padding: 3px 1rem;
            // padding: 3px
        }
        h6.interval {
            background-color: color(primary);
        }
        h6.filled {
            background-color: color(secondary);
        }
        h6.open {
            background-color: color(success);
            animation: shockwaveJump 1s ease-out infinite;
            color: color(base-inverse)
        }

        h6.close {
            background-color: color(warning);
            color: color(base-inverse)
        }

        @keyframes shockwaveJump {
            0% {
                transform: scale(1);
            }
            40% {
                transform: scale(1.08);
            }
            50% {
                transform: scale(0.98);
            }
            55% {
                transform: scale(1.02);
            }
            60% {
                transform: scale(0.98);
            }
            100% {
                transform: scale(1);
            }
        }
    }
    .pool-card-body {
        padding: 0.5rem 1rem 1rem 1rem;
        // .pool-title {
        //     margin-left: 1rem;
        // }
        .pool-social {
            li {
                padding: 0rem 0.5rem;
            }
            a {
                svg {
                    fill: color-icon(social);
                    transition: fill 0.15s ease;

                    .invert-color & {
                        fill: color-icon(social-inverse);
                    }
                }

                &:hover {
                    svg {
                        fill: color-icon(social-hover);

                        .invert-color & {
                            fill: color-icon(social-hover-inverse);
                        }
                    }
                }
            }
        }
        .pool-img {
            img {
                // width: 100px;
                border-radius: 10px;
                border: 1.5px solid color(primary);
                // margin-right: 1rem;
            }
        }
        .pool-details {
            margin-top: 1rem;
        }
        .connect-wallet-notice-pie {
            font-size: 10px;
            line-height: 15px;
            text-align: center;
        }
    }
}

@include media("<=large") {
    .pool-card-status {
        h6.interval {
            font-size: 12px;
        }
    }
    .whitelist-mobile {
        width: 100% !important;
        margin-top: 1rem !important;
        .whitelist-container {
            margin: auto;
            
            .register-container {
                // margin-top: 0 !important;
                justify-content: left;
                align-items: center;
                flex-direction: row-reverse !important;
                .register-now-text {
                    font-size: 17px;
                    margin-right: 0.5rem !important;
                }
            }
        }
    }
    .social-list {
        justify-content: center;
        margin-top: 0.5rem;
    }
    .text-xs {
        font-size: 14px;
    }
    h5 {
        font-size: 16px;
    }
    .extra-details {
        .col {
            padding-left: 5px;
            padding-right: 5px;
            text-align: center;
        }
    }
}
