.section-header {
    h2 {
        // border-bottom: 5px solid color(primary);
        // padding-bottom: 5px;
        display: inline-block;
        padding-bottom: 8px;
        position: relative;

        // text-decoration-line: underline;
        // text-decoration-color: color(primary);
        // text-underline-offset: 10px;
        // text-underline-position: ;
    }

    h2:before{
        content: "";
        position: absolute;
        width: 40%;
        height: 1px;
        bottom: 0;
        left: 30%;
        border-bottom: 5px color(primary);
        border-bottom-style: solid;
        // border-start-end-radius: 10px;
        border-radius: 10px;
    }
}

@include media( '<=large' ) {
    .section-sub-header {
        font-size: 18px;
        line-height: 22px;
    }
}
