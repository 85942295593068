// Built (core/sections/_built.scss) -----------------------
$built--padding-t__mobile:		null;		// built, top padding (mobile)
$built--padding-b__mobile: 		null;		// built, bottom padding (mobile)
$built--padding-t__desktop: 		null;		// built, top padding (desktop)
$built--padding-b__desktop: 		null;		// built, bottom padding (desktop)
$built-header--padding__mobile: 	null;		// built header, bottom padding (mobile)
$built-header--padding__desktop:	null;		// built header, bottom padding (desktop)
$built-items--padding__mobile: 	null;		// padding between built items (mobile)
$built-items--padding__desktop: 	null;		// padding between built items (desktop)
$built-item--width: 				475px;		// built item, width
$built-item--inner-padding-v: 	24px;		// built item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$built-item--inner-padding-h: 	24px;		// built item, inner left and right padding (it inherits $tiles-item--inner-padding-h unless you replace null with a new value)
$built-slider--max-w:             320px;      // built slider, max-width