.staking-list-page {
    // height: 85vh;
    .pool-staking {
        margin-bottom: 5rem;
    }
    .deatils-table {
        // margin-top: 32px;
        border-radius: 10px;
        background-color: color(heading-inverse);
        border-right: 1.5px solid color(primary);
        border-bottom: 1.5px solid color(primary);
        .table-title {
            background: linear-gradient(to left, #111427, #124096, #0a4fce, #0a4fce);
            padding: 0.7rem;
            border-radius: 10px;
            margin-bottom: 32px;
            // box-shadow: 1px 1px 5px -1px color(body-shadow)
            border: 1.5px solid color(primary);
        }
        .my-stake {
            color: white;
            margin-bottom: 1rem;
            text-align: center;
        }
        // padding: 0rem 2rem;
        .staking-details {
            padding: 0rem 2rem;
            margin-bottom: 2rem;
        }
        .stake-form {
            margin-bottom: 1.5rem;
            padding-bottom: 1.5rem;
            border-bottom: 1.5px solid color(primary);
        }
        .button-primary:disabled {
            color: color(heading);
        }
    }
    .staking-table {
        // background-color: red;
        // width: fit-content;
        // margin-top: 2rem;
    }
    .staking-table-body {
        background-color: color(heading-inverse);
        width: fit-content;
        overflow: hidden;
        border: 1.5px solid color(primary);
        border-radius: 10px;
    }
    .staking-pool-header {
        min-width: 37rem;
        border-bottom: 1px dashed color(primary);
        background: linear-gradient(to left, #111427, #124096, #0a4fce, #0a4fce);
        padding: 0.2rem 2rem 0.2rem 2rem;
        color: white;
    }
    .staking-pool-body {
        min-width: 37rem;
        padding: 0rem 2rem 0rem 2rem;
    }
    .blink-pool-body {
        animation: blink 5s infinite;
    }
    .table-item {
        // padding: 0.1rem 1rem;
        font-size: 1rem;
        white-space: nowrap;
    }
    .table-item-header {
        // border-right: 1px dashed color(primary);
        padding: 0.8rem 0.7rem;
        white-space: nowrap;
    }
    .details-btn-item-mobile {
        display: none;
    }
    .staking-work {
        display: flex;
        flex-direction: column;
    }

    // .blink {
    //     width: 400px;
    //     height: 400px;
    //     border: 1px solid black;
    //     animation: blink 5s infinite;
    // }
    @keyframes blink {
        0% {
            background: #00c2be3d;
        }
        20% {
            background: inherit;
        }
        40% {
            background: #00c2be3d;
        }
        60% {
            background: inherit;
        }
        100% {
            background: #00c2be3d;
        }
        // 100% {
        //     background: inherit;
        // }
    }

    @-webkit-keyframes blink {
        0% {
            background: #00c2be3d;
        }
        20% {
            background: inherit;
        }
        40% {
            background: #00c2be3d;
        }
        60% {
            background: inherit;
        }
        80% {
            background: #00c2be3d;
        }
        100% {
            background: inherit;
        }
    }
}

@include media("<=medium") {
    .staking-table-body {
        overflow: scroll;
    }
    .staking-pool-header {
        min-width: unset !important;
        padding: 0.2rem 2rem 0.2rem 1.2rem !important;
    }
    .staking-pool-body {
        min-width: unset !important;
        padding: 0rem 2rem 0rem 1.2rem !important;
    }
    .table-item {
        // width: 3rem;
        font-size: 0.85rem !important;
        text-align: center;
    }
    .table-item-header {
        // width: 3rem;
        font-size: 0.85rem !important;
        text-align: center;
    }
    .details-btn-item {
        display: none;
    }
    .details-btn-item-mobile {
        display: block !important;
    }
    .details-btn {
    }
    .staking-work {
        width: 100% !important;
        margin-top: 1rem;
    }
}
