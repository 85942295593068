// Hide elements, for screen readers only
.screen-reader {
	position: absolute;
	width: 1px !important;
	height: 1px !important;
	padding: 0;
	overflow: hidden;
	clip: rect(0,0,0,0);
	white-space: nowrap;
	border: 0;
}

// Reset lists style
.list-reset {
	list-style: none;
	padding: 0;

	li {
		margin: 0;
	}
}

// Text alignment
.ta-l {
  text-align: left;
}

.ta-c {
  text-align: center;
}

.ta-r {
  text-align: right;
}

// Font weight
.fw-400 {
	font-weight: 400;
}

.fw-500 {
	font-weight: 500;
}

.fw-600 {
	font-weight: 600;
}

.fw-700 {
	font-weight: 700;
}

// Text color
.text-color-high {
	color: color(high-contrast);
}

.text-color-mid {
	color: color(mid-contrast);
}

.text-color-low {
	color: color(low-contrast);
}

.text-color-primary {
	color: color(primary);
}

.text-color-secondary {
	color: color(secondary);
}

.text-color-error {
	color: color(error);
}

.text-color-warning {
	color: color(warning);
}

.text-color-success {
	color: color(success);
}

.invert-color {

	.text-color-high {
		color: color(high-contrast-inverse);
	}

	.text-color-mid {
		color: color(mid-contrast-inverse);
	}

	.text-color-low {
		color: color(low-contrast-inverse);
	}
}

// Text transform
.tt-u {
	text-transform: uppercase;
}

// Images
.image-full {
	width: 100%;
}

.image-larger {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% + #{$image-larger--extra-width * 2});
	max-width: 100vw;
}

// Helper class for coloured bg
.has-bg-color {
	position: relative;
	background-color: color-bg(bg-color);
	z-index: 0;
}

// Helper class for box shadow
.has-shadow {
	box-shadow: color-bg(shadow);
}

img.has-shadow {
	border-radius: $img-radius;
}

// Dividers
.has-top-divider {
    @include divider(before);
}

.has-bottom-divider {
    @include divider(after);
}

.invert-color {

	.has-top-divider {
	    @include divider(before, inverse);
	}

	.has-bottom-divider {
	    @include divider(after, inverse);
	}
}

// For centering content
.center-content {
	text-align: center;

	img,
	svg,
	video {
		margin-left: auto;
		margin-right: auto;
	}

	.button-group {
		justify-content: center;
	}
}

.d-flex-token {
	display: flex;
	// flex-direction: row;
	align-items: center;
	justify-content: center;
	img {
		width: 45%;
		height: fit-content;
	}
}

.partner-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.col-lg-3 {
	width: 25%;
	justify-content: center;
}

.partner-logo {
	height: 4rem;
	display: flex;
	padding: 16px;
	margin: auto;
}

.media-logo {
	width: 12rem;
	display: flex;
	padding: 16px;
	margin: auto;
}

.media-logo-yahoo {
	width: 8rem;
	display: flex;
	padding: 8px;
	margin: auto;
}

.media-logo-benzinga {
	width: 10rem;
	display: flex;
	padding: 16px;
	margin: auto;
}

.partner-logo-ahc {
	height: 4rem;
	display: flex;
	padding: 16px;
	margin: auto;
	margin-left: 0;
}

@include media( '<=large' ) {
	.d-flex-token {
		flex-direction: column;
		img {
			width: 100%;
			height: fit-content;
		}
	}

	.partner-logo { 
		height: unset;
		width: 10rem;
	}

	.partner-logo-ahc { 
		height: 3.2rem;
		margin: auto;
		// width: 10rem;
	}

	.media-logo-yahoo {
		height: 3.5rem;
		width: unset;
	}

	.col-md-6 {
		width: 50%;
	}
}

// Responsive video
.responsive-video {
	position: relative;
	padding-bottom: 56.25%; // 16:9
	height: 0;

	&.is-4-3 {
		padding-bottom: 75%; // 4:3
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
    }
}

// Spacing, margin
.m-0 {
	margin: 0 !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.ml-0 {
	margin-left: 0 !important;
}

.m-4 {
	margin: 4px !important;
}

.mt-4 {
	margin-top: 4px !important;
}

.mr-4 {
	margin-right: 4px !important;
}

.mb-4 {
	margin-bottom: 4px !important;
}

.ml-4 {
	margin-left: 4px !important;
}

.m-8 {
	margin: 8px !important;
}

.mt-8 {
	margin-top: 8px !important;
}

.mr-8 {
	margin-right: 8px !important;
}

.mb-8 {
	margin-bottom: 8px !important;
}

.ml-8 {
	margin-left: 8px !important;
}

.m-12 {
	margin: 12px !important;
}

.mt-12 {
	margin-top: 12px !important;
}

.mr-12 {
	margin-right: 12px !important;
}

.mb-12 {
	margin-bottom: 12px !important;
}

.ml-12 {
	margin-left: 12px !important;
}

.m-16 {
	margin: 16px !important;
}

.mt-16 {
	margin-top: 16px !important;
}

.mr-16 {
	margin-right: 16px !important;
}

.mb-16 {
	margin-bottom: 16px !important;
}

.ml-16 {
	margin-left: 16px !important;
}

.m-24 {
	margin: 24px !important;
}

.mt-24 {
	margin-top: 24px !important;
}

.mr-24 {
	margin-right: 24px !important;
}

.mb-24 {
	margin-bottom: 24px !important;
}

.ml-24 {
	margin-left: 24px !important;
}

.m-32 {
	margin: 32px !important;
}

.mt-32 {
	margin-top: 32px !important;
}

.mr-32 {
	margin-right: 32px !important;
}

.mb-32 {
	margin-bottom: 32px !important;
}

.ml-32 {
	margin-left: 32px !important;
}

// Spacing, padding
.p-0 {
	padding: 0 !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.pr-0 {
	padding-right: 0 !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pl-0 {
	padding-left: 0 !important;
}

.p-4 {
	padding: 4px !important;
}

.pt-4 {
	padding-top: 4px !important;
}

.pr-4 {
	padding-right: 4px !important;
}

.pb-4 {
	padding-bottom: 4px !important;
}

.pl-4 {
	padding-left: 4px !important;
}

.p-8 {
	padding: 8px !important;
}

.pt-8 {
	padding-top: 8px !important;
}

.pr-8 {
	padding-right: 8px !important;
}

.pb-8 {
	padding-bottom: 8px !important;
}

.pl-8 {
	padding-left: 8px !important;
}

.p-12 {
	padding: 12px !important;
}

.pt-12 {
	padding-top: 12px !important;
}

.pr-12 {
	padding-right: 12px !important;
}

.pb-12 {
	padding-bottom: 12px !important;
}

.pl-12 {
	padding-left: 12px !important;
}

.p-16 {
	padding: 16px !important;
}

.pt-16 {
	padding-top: 16px !important;
}

.pr-16 {
	padding-right: 16px !important;
}

.pb-16 {
	padding-bottom: 16px !important;
}

.pl-16 {
	padding-left: 16px !important;
}

.p-24 {
	padding: 24px !important;
}

.pt-24 {
	padding-top: 24px !important;
}

.pr-24 {
	padding-right: 24px !important;
}

.pb-24 {
	padding-bottom: 24px !important;
}

.pl-24 {
	padding-left: 24px !important;
}

.p-32 {
	padding: 32px !important;
}

.pt-32 {
	padding-top: 32px !important;
}

.pr-32 {
	padding-right: 32px !important;
}

.pb-32 {
	padding-bottom: 32px !important;
}

.pl-32 {
	padding-left: 32px !important;
}

// Spacing, spacers
.spacer-4 {
	padding-top: 4px !important;
}

.spacer-8 {
	padding-top: 8px !important;
}

.spacer-12 {
	padding-top: 12px !important;
}

.spacer-16 {
	padding-top: 16px !important;
}

.spacer-24 {
	padding-top: 24px !important;
}

.spacer-32 {
	padding-top: 32px !important;
}

.spacer-48 {
	padding-top: 48px !important;
}

.spacer-64 {
	padding-top: 64px !important;
}

@include media( '<=medium' ) {

	.ta-l-mobile {
		text-align: left;
	}

	.ta-c-mobile {
		text-align: center;
	}

	.ta-r-mobile {
		text-align: right;
	}

	.center-content-mobile {
		text-align: center;

		img,
		svg,
		video {
			margin-left: auto;
			margin-right: auto;
		}

		.button-group {
			justify-content: center;
		}
	}

	// Spacing, spacers
	.spacer-4-mobile {
		padding-top: 4px !important;
	}

	.spacer-8-mobile {
		padding-top: 8px !important;
	}

	.spacer-12-mobile {
		padding-top: 12px !important;
	}

	.spacer-16-mobile {
		padding-top: 16px !important;
	}

	.spacer-24-mobile {
		padding-top: 24px !important;
	}

	.spacer-32-mobile {
		padding-top: 32px !important;
	}

	.spacer-48-mobile {
		padding-top: 48px !important;
	}

	.spacer-64-mobile {
		padding-top: 64px !important;
	}	
}

@include media( '>medium' ) {

	.ta-l-desktop {
		text-align: left;
	}

	.ta-c-desktop {
		text-align: center;
	}

	.ta-r-desktop {
		text-align: right;
	}

	.center-content-desktop {
		text-align: center;

		img,
		svg,
		video {
			margin-left: auto;
			margin-right: auto;
		}

		.button-group {
			justify-content: center;
		}
	}
}
