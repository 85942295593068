.connect-wallet-notice {
    border-right: 1.5px solid color(primary);
    border-bottom: 1.5px solid color(primary);
    border-left: 1.5px solid color(primary);
    border-radius: 10px;
    background-color: color(heading-inverse);
    text-align: center;
    padding: 1rem;
}

.loading-container-pool-details {
    margin-top: 6rem;
}

.staking-details-page {
    margin-bottom: 5rem;
    .token-symbol-link {
        font-weight: 600;
        text-decoration: underline;
    }
    .button-primary:disabled {
        color: color(heading);
    }

    .details-table-wrapper {
        // background-color: red;
        border-radius: 10px;
    }

    .deatils-table {
        margin-top: 32px;
        border-radius: 10px;
        background-color: color(heading-inverse);
        // box-shadow: 1px 1px 10px -5px color(body-shadow);
        border-right: 1.5px solid color(primary);
        border-bottom: 1.5px solid color(primary);
        // border-left: 1.5px solid color(primary);
        // background: linear-gradient(color(heading-inverse), color(primary)), linear-gradient(color(heading-inverse), color(primary));
        // background-size: 1.5px 100%, 100% 1.5px;
        // background-position: bottom left, bottom center;
        // background-repeat: no-repeat;
        // border-top: none;
        .table-title {
            background: linear-gradient(to left, #111427, #124096, #0a4fce, #0a4fce);
            padding: 0.7rem;
            border-radius: 10px;
            margin-bottom: 32px;
            // box-shadow: 1px 1px 5px -1px color(body-shadow)
            border: 1.5px solid color(primary);
        }
        .details {
            padding: 0rem 1.5rem;
            margin-bottom: 1.1rem;
            .status {
                margin-bottom: 1rem;
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
                h6 {
                    border-radius: 20px;
                    padding: 3px 1rem;
                    text-align: center;
                    // padding: 3px
                }
                h6.interval {
                    background-color: color(primary);
                    animation: shockwaveJump 1s ease-out infinite;
                }
                h6.filled {
                    background-color: color(secondary);
                }
                h6.open {
                    background-color: color(success);
                    animation: shockwaveJump 1s ease-out infinite;
                    color: color(base-inverse);
                }
    
                h6.close {
                    background-color: color(warning);
                    animation: shockwaveJump 1s ease-out infinite;
                    color: color(base-inverse);
                }
    
                @keyframes shockwaveJump {
                    0% {
                        transform: scale(1);
                    }
                    40% {
                        transform: scale(1.01);
                    }
                    50% {
                        transform: scale(1);
                    }
                    // 55% {
                    //     transform: scale(1.01);
                    // }
                    60% {
                        transform: scale(0.99);
                    }
                    100% {
                        transform: scale(1);
                    }
                }
            }

            .button-primary  {
                width: 100%;
            }
            
        }
        .border-right {
            border-right: 1.5px solid color(primary);
        }
    }
    .primary-color {
        color: color(primary);
    }
    .button-primary:disabled {
        color: color(heading);
    }

    .swap-interface {
        // .form-input {
        //     background-color: transparent;
        // }
        display: grid;
        padding: 1rem;
        // border: 0rem solid white;
        .form-content,
        .overlay-error-content {
            grid-area: 1 / 1;
        }
        .overlay-error-content {
            background-color: rgba(17, 19, 37, 0.8);
            border: 0.5rem solid color(base);
            // margin: auto;
            text-align: center;
            // margin-top: 10rem;
            height: 100%;
            display: flex;
            z-index: 1;
            h6 {
                margin: auto;
            }
        }
        .form-input[disabled] {
            background-color: transparent;
            border-color: #252b45;
        }
    }

    .view-on-explorer {
        margin-bottom: -23px;
        display: flex;
        line-height: 0px;
        a {
            width: 100%;
            text-align: right;
        }
    }

    @include media( '<=medium' ) {
        p {
            font-size: 14px;
            line-height: 20px;
        }
        .deatils-table {
            .details {
                padding: 0rem 0.5rem;
            }
            p {
                margin-bottom: 16px;
            }
        }
        .swap-interface {
            padding: 1rem 0.5rem;
        }
    }
}

.button-max {
    max-width: fit-content;
    max-height: 1.5rem;
    padding: 4px 10px;
    margin-left: auto;
    margin-top: -2.5rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
}

// .whitelist-container {
//     width: 100%;
//     .open-in {
//         margin-bottom: 0.25rem !important;
//     }
//     .whitelisted-in {
//         margin-top: 0.25rem !important;
//     }
// }
