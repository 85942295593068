.connect-wallet-notice {
    border-right: 1.5px solid color(primary);
    border-bottom: 1.5px solid color(primary);
    border-left: 1.5px solid color(primary);
    border-radius: 10px;
    background-color: color(heading-inverse);
    text-align: center;
    padding: 1rem;
}

.loading-container-pool-details {
    margin-top: 6rem;
}

.pool-details-page {
    .flot-form {
        margin-top: -0.8rem;
    }
    
    .pool-brand-details {
        display: flex;
        background-color: color(heading-inverse);
        padding: 1.5rem;
        border-radius: 10px;
        border: 1.5px solid color(primary);
        .pool-img {
            img {
                width: 150px;
                border-radius: 10px;
                border: 1.5px solid color(primary);
            }
        }
        .pool-title {
            margin-left: 32px;
            .title-status {
                display: flex;
            }
        }
        .pool-status {
            width: fit-content;
            // padding: 0 1rem;
            margin-left: auto;
            margin-top: -16px;
            margin-right: 1rem;
            h6 {
                border-radius: 20px;
                padding: 3px 1rem;
                // padding: 3px
            }
            h6.interval {
                background-color: color(primary);
            }
            h6.filled {
                background-color: color(secondary);
            }
            h6.open {
                background-color: color(success);
                animation: shockwaveJump 1s ease-out infinite;
                color: color(base-inverse);
            }

            h6.close {
                background-color: color(warning);
                color: color(base-inverse);
            }

            @keyframes shockwaveJump {
                0% {
                    transform: scale(1);
                }
                40% {
                    transform: scale(1.08);
                }
                50% {
                    transform: scale(0.98);
                }
                55% {
                    transform: scale(1.02);
                }
                60% {
                    transform: scale(0.98);
                }
                100% {
                    transform: scale(1);
                }
            }
        }
        .pool-social {
            li {
                padding: 0rem 1rem 0rem 0rem;
            }
            li:last-child {
                padding: 0rem;
            }
            a {
                svg {
                    fill: (white);
                    // fill: color-icon(social);
                    transition: fill 0.15s ease;

                    .invert-color & {
                        fill: color-icon(social-inverse);
                    }
                }

                &:hover {
                    svg {
                        fill: color-icon(social-hover);

                        .invert-color & {
                            fill: color-icon(social-hover-inverse);
                        }
                    }
                }
            }
        }
    }

    .token-symbol-link {
        font-weight: 600;
        text-decoration: underline;
    }

    .details-table-wrapper {
        // background-color: red;
        border-radius: 10px;
    }

    .deatils-table {
        margin-top: 32px;
        border-radius: 10px;
        background-color: color(heading-inverse);
        // box-shadow: 1px 1px 10px -5px color(body-shadow);
        border-right: 1.5px solid color(primary);
        border-bottom: 1.5px solid color(primary);
        // border-left: 1.5px solid color(primary);
        // background: linear-gradient(color(heading-inverse), color(primary)), linear-gradient(color(heading-inverse), color(primary));
        // background-size: 1.5px 100%, 100% 1.5px;
        // background-position: bottom left, bottom center;
        // background-repeat: no-repeat;
        // border-top: none;
        .table-title {
            background: linear-gradient(to left, #111427, #124096, #0a4fce, #0a4fce);
            padding: 0.7rem;
            border-radius: 10px;
            margin-bottom: 32px;
            // box-shadow: 1px 1px 5px -1px color(body-shadow)
            border: 1.5px solid color(primary);
        }
        .details {
            padding: 0rem 1.5rem;
        }
        .border-right {
            border-right: 1.5px solid color(primary);
        }
    }
    .primary-color {
        color: color(primary);
    }
    .button-primary:disabled {
        color: color(heading);
    }

    .swap-progress {
        progress {
            width: 100%;
        }
        .progress-bar {
            background-color: whiteSmoke;
            border-radius: 2px;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25) inset;

            width: 250px;
            height: 20px;

            position: relative;
            display: block;
        }

        .progress-bar > span {
            background-color: blue;
            border-radius: 2px;

            display: block;
            text-indent: -9999px;
        }

        .progress-markups {
            margin-top: -8px;
        }
    }

    .swap-interface {
        // .form-input {
        //     background-color: transparent;
        // }
        display: grid;
        padding: 1rem;
        // border: 0rem solid white;
        .form-content,
        .overlay-error-content {
            grid-area: 1 / 1;
        }
        .overlay-error-content {
            background-color: rgba(17, 19, 37, 0.8);
            border: 0.5rem solid color(base);
            // margin: auto;
            text-align: center;
            // margin-top: 10rem;
            height: 100%;
            display: flex;
            z-index: 1;
            h6 {
                margin: auto;
            }
        }
        .form-input[disabled] {
            background-color: transparent;
            border-color: #252b45;
        }
    }

    .governance-details {
        display: grid;
        .details,
        .overlay-message {
            grid-area: 1 / 1;
        }
        .overlay-message {
            border-radius: 1rem;
            background-color: rgba(17, 19, 37, 0.9);
            // border: 0.5rem solid color(base);
            // margin: auto;
            text-align: center;
            // margin-top: 10rem;
            height: 100%;
            display: flex;
            z-index: 1;
            justify-content: center;
            // h6 {
            //     margin: auto;
            // }
        }
    }

    .view-on-explorer {
        margin-bottom: -23px;
        display: flex;
        line-height: 0px;
        a {
            width: 100%;
            text-align: right;
        }
    }

    @include media( '<=medium' ) {
        p {
            font-size: 14px;
            line-height: 20px;
        }
        .flot-form {
            margin-top: 1rem;
        }
        .social-center {
            justify-content: center !important;
        }
        .pool-brand-details {
            padding: 1rem 0.5rem;
            flex-direction: column;
            align-items: center;
            .pool-img {
                width: 4rem;
            }
            .pool-title {
                margin-left: 0px;
                .title-status {
                    margin-top: 1rem;
                    flex-direction: column-reverse;
                    align-items: center;
                    .pool-status {
                        width: fit-content;
                        // padding: 0 1rem;
                        margin-left: 0;
                        margin-top: 0px;
                        margin-right: 0rem;
                    }
                }
                .pool-description {
                    text-align: center;
                }
            }
        }
        .deatils-table {
            .details {
                padding: 0rem 0.5rem;
            }
            p {
                margin-bottom: 16px;
            }
        }
        .swap-interface {
            padding: 1rem 0.5rem;
        }
    }
}

.button-max {
    max-width: fit-content;
    max-height: 1.5rem;
    padding: 4px 10px;
    margin-left: auto;
    margin-top: -2.5rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
}

.whitelist-container {
    width: 100%;
    .open-in {
        margin-bottom: 0.25rem !important;
    }
    .whitelisted-in {
        margin-top: 0.25rem !important;
    }
}
