.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

.modal-header {
    border-bottom: none;
    background-color: color-bg(modal);
    border-bottom: 0.5px dashed color(primary);
    padding: 0.7rem 1rem;
    .modal-title {
        margin: 0;
        font-size: 1rem;
    }
}

.modal-header>.close {
	span {
		display: none;
	}
}

.modal-header>.close {
    background: transparent;
	border: 0;
	outline: 0;
	padding: 0;
	cursor: pointer;
	position: absolute;
	right: $modal-close-position;
	top: $modal-close-position;
	width: $modal-close-icon--size;
	height: $modal-close-icon--size;

	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -($modal-close-icon--thickness / 2);
		margin-left: -($modal-close-icon--size / 2);
		width: $modal-close-icon--size;
		height: $modal-close-icon--thickness;
		background: color-icon(modal);
		border-radius: $modal-close-icon--radius;
	}

	&::before {
		transform: rotate(225deg);
	}

	&::after {
		transform: rotate(-45deg);
	}

	&:hover {

		&::before,
		&::after {
			background: color-icon(modal-hover);
		}
	}
} 

.modal-content {
    max-height: calc(100vh - #{$modal--outer-padding-v__mobile} * 2);
	overflow: auto;
	position: relative;
	width: calc(100%  - ( #{$container--padding__mobile} * 2 ));
	min-width: $modal--min-width;
	max-width: $modal--max-width;
	margin-left: $container--padding__mobile;
	margin-right: $container--padding__mobile;
	background: color-bg(modal);
}

.wallet-item {
    background-color: color-bg(modal-outer);
    border: 1px dotted color(primary);
    // margin: 1.3rem 0.3rem;
    cursor: pointer;
    border-radius: 5px;
}

.wallet-item:hover {
    background-color: color-bg(body);
    border-style: solid;
}

@include media( '>medium' ) {

	.modal-content {
		margin: 0 auto;
		max-height: calc(100vh - #{$modal--outer-padding-v__desktop} * 2);
	}
}