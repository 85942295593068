.cta {
    .content {
        border: 1px color(primary) solid;
        padding: 30px 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
    }

    @include media( '<=medium' ) {
        .content {
            flex-direction: column;
            padding: 30px 20px;
        }

        .cta-action {
            margin-top: 1rem;
        }
    }
    
}