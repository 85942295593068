.paragraph {
	line-height: 28px;
}

.text-content {
	font-size: 18px;
} 

@include media( '<=large' ) {
	.text-content {
		font-size: 16px;
		line-height: 23px;
	}
}



.features-list {
    li {
        display: flex;
		align-items: center;
		margin-bottom: $spacing--shared-v / 2;
		justify-content: space-between;
		margin-bottom: 0;
		padding: 14px 0;
		@include divider(after);
		line-height: 24px;
		text-align: left;

		// &::before {
		// 	background: get-color(dark, 3);
		// }

		&::before {
			content: '';
			display: block;
			width: 100px;
			height: 12px;
			margin-left: 12px;
			background-image: inline-svg('<svg width="16" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M1 5h14v2H1z" fill="' + get-color(dark, 3) + '" fill-rule="nonzero"/></svg>');
			background-repeat: no-repeat;
		}

		&.is-checked {

			&::before {
				background-image: inline-svg('<svg width="16" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M5.6 6.4L1.6 4 0 5.6 5.6 12 16 1.6 14.4 0z" fill="' + get-color(alert, success) + '" fill-rule="nonzero"/></svg>');
			}
		}

		.text-white {
			color: color(heading)
		}
    }
    
    
}